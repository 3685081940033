.loyalty {
    background-image: url('/public/loyaltyBack.webp');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 92vh;
}

.loyalty__second__block {
    display: flex;
    justify-content: space-between;
}

.loyalty__second__block>.images {
    width: 50%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
}

.loyalty__second__block>.images>img {
    width: 47.5%;
    height: 220px;
    margin-bottom: 20px;
    border-radius: 20px;
    object-fit: cover;
}

.loyalty__content {
    width: 45%;
}

.loyalty__content>p>span{
    color: #68070D;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.loyalty__navigate {
    color: #68070D;
    margin-top: 15px;
    width: 100%;
}

.loyalty__navigate>div {
    width: 100%;
}

.loyalty__btn {
    width: 100px;
    margin: 15px 0;
    padding: 6px 20px 10px 20px;
}

.loyalty__trird__block {
    display: flex;
    justify-content: space-between;
}

.loyalty__trird__block>.item__line__1440,
.loyalty__trird__block>.item__line__1024 {
    height: 150px;
}

.loyalty__content__item {
    width: 30%;
    display: flex;
    gap: 20px;
    padding: 10px 0;
    box-sizing: border-box;
}

.loyalty__content__item>.left {
    padding-top: 10px;
}

.air__image {
    transform: rotate(45deg);
    border-radius: 30px;
    margin-left: 4px;
}

.loyalty__content__item__mobile {
    display: none;
    width: 30%;
    flex-direction: column;
    padding: 10px 0;
    box-sizing: border-box;
}

.loyalty__content__item__mobile>.left {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
}

@media(max-width:1024px) {
    .loyalty__content__item {
        display: none;
    }

    .loyalty__content__item__mobile {
        display: flex;
    }
}

@media(max-width:768px) {
    .loyalty__first__block {
        width: 50%;
        padding-left: 40px;
    }

    .loyalty__second__block>.images>img {
        width: 100%;
        height: 150px;
    }

    .loyalty__trird__block {
        flex-direction: column;
    }

    .loyalty__trird__block>.item__line__1440,
    .loyalty__trird__block>.item__line__1024 {
        display: none;
    }

    .loyalty__content__item__mobile {
        width: 100%;
        padding-bottom: 0;
        border-bottom: #b5b5b6 2px solid;
    }

    .loyalty__content__item__mobile>.left {
        width: min-content;
        margin: 0 auto;
    }

    .loyalty__content__item__mobile:nth-child(5) {
        padding: 10px 0;
        border: none;
        border-bottom: #b5b5b6 2px solid;
    }

    .loyalty__content__item__mobile:last-child {
        border: none;
    }
}

@media(max-width:500px) {
    .loyalty__second__block {
        flex-direction: column;
        gap: 30px;
    }

    .loyalty__second__block>.images {
        width: 100%;
    }

    .loyalty__second__block>.images>img {
        height: 200px;
    }

    .loyalty__content {
        width: 100%;
    }
}

@media(max-width:426px) {
    .loyalty__first__block {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
}