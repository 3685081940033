.footer__content {
    display: flex;
    justify-content: space-between;
    margin: 30px auto;
    font-size: 18px;
    cursor: default;
}

.footer__first__block {
    width: 350px;
}

.footer__first__block>p {
    margin-bottom: 15px;
}

.footer__second__block {
    width: 600px;
    display: flex;
    justify-content: space-between;
}

.messengers{
    display: flex;
    gap: 20px;
}

.messengers>a>img{
    width: 30px;
    height: 30px;
}

.second {
    width: 125px;
}

.second>div {
    cursor: pointer;
    margin-top: 8px;
}

.second>div:hover {
    text-decoration: underline;
}

.third {
    width: 130px;
}

.third>div {
    cursor: pointer;
    margin-bottom: 8px;
}

.third>div:hover {
    text-decoration: underline;
}

.fourth {
    width: 150px;
}

.second>.title {
    font-size: 20px;
    margin: 15px 0;
}

.third>.title {
    font-size: 20px;
    margin: 15px 0;
}

.fourth>.title {
    font-size: 20px;
    margin: 15px 0;
}

.copyright {
    width: 100%;
    max-width: 100%;
    text-align: center;
    align-content: center;
    margin: 0 auto;
    height: 60px;
    background-color: black;
    color: white;
    font-size: 18px;
    font-weight: 300;
}

.logo__copyright {
    width: 60px;
}

@media (max-width:1024px) {
    .footer__second__block {
        width: 500px;
    }
}

@media (max-width: 950px) {
    .footer__content {
        flex-direction: column;
    }

    .footer__first__block>img{
        width: 30%;
        object-fit: cover;
        height: auto;
        padding-right: 10px;
        box-sizing: border-box;
    }

    .footer__first__block {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }

    .mess__img{
        width: 50px;
        height: 50px;
        margin: 0;
    }

    .footer__first__block>p{
        width: 50%;
    }

    .footer__second__block {
        margin: 20px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .fourth>p{
        font-size: 18px;
    }
}

@media(max-width:500px){
    .footer__first__block{
        flex-wrap: wrap;
    }

    .footer__first__block>img{
        width: 100%;
        height: auto;
        padding: 0 30px;
        box-sizing: border-box;
    }

    .footer__first__block>p{
        width: 100%;
    }

    .footer__second__block{
        flex-direction: column;
        width: min-content;
    }

    .second>.title {
        font-size: 24px;
        margin: 20px 0 10px 0;
    }
    
    .third>.title {
        font-size: 24px;
        margin: 20px 0 10px 0;
    }
    
    .fourth>.title {
        font-size: 24px;
        margin: 20px 0 10px 0;
    }

    .copyright {
        font-size: 16px;
        font-weight: 300;
    }
    
    .logo__copyright {
        width: 55px;
    }
}