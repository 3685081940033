.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    height: 600px;
    position: relative;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 24px;
}

.modal-content>form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modal-content>form>button {
    width: min-content;
    padding: 10px 50px 14px 50px;
    background-color: #68070D;
    color: white;
    font-size: 22px;
    margin: auto;
    border-radius: 15px;
    cursor: pointer;
    border: 2px, solid, #68070D;
    background-color: #68070D;
    color: white;
    transition: .5s ease;
    /* margin-top: 20px; */
}

.modal-content>form>button:hover {
    transition: .5s ease;
    background-color: #fff;
    border: 2px solid #68070D;
    color: #68070D;
    box-shadow: 0 4px 6px gray;
}

.modal-content>.title {
    width: min-content;
    margin: 20px auto;
    white-space: nowrap;
    color: #68070D;
}

.point__form__group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.point__form__group:nth-child(3) {
    height: 75px;
}

.point__form__group>label {
    font-size: 22px;
}

.point__form__group>input {
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.point__form__group>textarea {
    height: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
}