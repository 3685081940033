.product {
    background-image: url('/public/back2.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
}

.product__first__block {
    height: 100%;
    width: 35%;
    background-color: rgba(243, 245, 249, 0.5);
    color: #68070D;
    align-content: center;
    padding-left: 10%;
}

.product__second__block {
    display: block;
    text-align: center;
}

.product__second__block>.title{
    margin: 20px 0;
}

.product__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    row-gap: 50px;
    margin-top: 40px;
    padding: 10px 0;
}

.product__content>.item__line__1440,
.product__content>.item__line__1024{
    height: 120px;
}

.content__item {
    width: 30%;
    display: flex;
    gap: 15px;
}

.content__item>.left {
    padding-top: 10px;
}

.content__item>.right {
    color: #68070D;
    text-align: start;
}

.content__item__mobile {
    display: none;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 20px;
    border-bottom: 2px solid #b5b5b6;
}

.content__item__mobile:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.content__item__mobile>.left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.product__third__block {
    margin: 20px 0;
    text-align: center;
}

.product__third__block>p {
    margin: 40px 0 80px 0;
}

.title__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title__content p {
    width: 40%;
    text-align: justify;
    line-height: 1.8;
}

.title__content div {
    width: 40%;
}

.product__third__block>.title{
    margin-top: 20px;
}

.services {
    width: 100%;
    display: flex;
    gap: 30px;
    margin-top: 30px;
}

.service {
    width: 100%;
    height: 350px;
    position: relative;
    display: flex;
}

.service img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.service__title {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 9;
    color: white;
    padding: 5px 10px;
    font-size: 18px;
    border-radius: 5px;
}

.product__fourth__block>.title__content>.title{
    width: 50%;
}

.product__fourth__block>.title__content>p{
    width: 45%;
}

.wraper1 {
    margin-top: 70px;
    text-align: center;
}

.wraper1>.subtitle{
    margin-bottom: 20px;
}

.feedback__title {
    margin: 10px 0 40px 0;
}

@media(max-width:1024px) {
    .content__item {
        width: 48%;
    }
}

@media(max-width:768px) {
    .title__content {
        flex-direction: column;
    }

    .product__fourth__block>.title__content>.title,
    .product__fourth__block>.title__content>p{
        width: 100%;
    }

    .product__fourth__block>.title__content>.title{
        margin-top: 30px;
    }

    .feedback__title {
        flex-direction: column;
    }

    .product__first__block {
        width: 50%;
        padding-left: 40px;
        box-sizing: border-box;
    }
}

@media(max-width:500px) {
    .content__item {
        display: none;
    }

    .content__item__mobile {
        display: flex;
        flex-direction: column;
    }

    .services {
        flex-direction: column;
    }

    .service {
        height: 200px;
    }
}

@media(max-width:426px) {
    .product__first__block {
        width: 100%;
        padding: 0 20px;
    }
}
