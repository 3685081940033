.feedback__container {
    width: 100%;
    overflow: hidden;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
}

.feedback__track {
    display: flex;
    white-space: nowrap;
    animation: scrollFeedback linear infinite;
    -webkit-animation: scrollFeedback linear infinite;
    padding-bottom: 6px;
    --translateDistance: 3.3
}

/* @keyframes scrollFeedback {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% / var(--translateDistance)));
    }
} */

@-webkit-keyframes scrollFeedback {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(calc(-100% / var(--translateDistance)));
        transform: translateX(calc(-100% / var(--translateDistance)));
    }
}

@keyframes scrollFeedback {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(calc(-100% / var(--translateDistance)));
        transform: translateX(calc(-100% / var(--translateDistance)));
    }
}


.feedback__item {
    flex: 0 0 300px;
    min-width: 300px;
    margin-right: 20px;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    white-space: normal;
    word-wrap: break-word;
}

.feedback__track > * {
    flex-shrink: 0;
}

.feedback__first__block{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.stars {
    display: flex;
    gap: 3px;
}

.comment p {
    margin: 0;
    line-height: 1.5;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.author__data {
    display: flex;
    align-items: center;
    gap: 10px;
}

.author__data img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #68070D;
    padding: 1px;
    object-fit: cover;
}

.author__name {
    font-size: 16px;
    font-weight: bold;
}

.author__position {
    color: gray;
    font-size: 14px;
}

.feedback__container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100px;
    background: linear-gradient(to right, rgba(241, 249, 251, 1), rgba(241, 249, 251, 0));
    pointer-events: none;
    z-index: 2;
}

.feedback__container::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100px;
    background: linear-gradient(to left, rgba(241, 249, 251, 1), rgba(241, 249, 251, 0));
    pointer-events: none;
    z-index: 2;
}

@media(max-width:768px){
    .feedback__item{
        flex: 0 0 200px;
        min-width: 200px;
    }
}

@media(max-width:500px){
    .feedback__track {
        animation: scrollFeedback 100s linear infinite;
    }

    .comment>p{
        font-size: 14px;
    }
}

@media(max-width:320px){
    .feedback__track {
        animation: scrollFeedback 80s linear infinite;
    }

    .feedback__item{
        flex: 0 0 150px;
        min-width: 150px;
    }
}
