.agent__detail__item{
    display: flex;
    align-items: center;
}

.agent__content{
    display: flex;
    justify-content: space-between;
}

.agent__table thead th{
    text-align: start;
}

.agent__table thead th:nth-child(1){
    width: 170px;
}

.agent__table thead th:nth-child(2){
    width: 170px;
}

.agent__add__or__deduct__btn{
    background-color: #4CAF50;
    height: 50px;
    width: 150px;
    text-align: center;
    border-radius: 15px;
    color: white;
    font-size: 18px;
}

.agent__add__or__deduct__btn:hover{
    opacity: 0.8;
    box-shadow: 0px 4px 10px rgb(0, 0, 0, 0.2);
}

.agent__tabs {
    display: flex;
    margin-bottom: 1em;
    margin-top: 40px;
}

.agent__tab {
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 2px solid #e4e5e9;
    box-sizing: border-box;
    transition: .4s ease;
}

.agent__tab.active {
    transition: .4s ease;
    border-bottom: 2px solid #68070D;
}

.agents__details__list {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin-bottom: 40px;
}

.agents__details__list thead th {
    color: #69070D;
    text-align: left;
    padding: 12px;
    font-weight: 300;
}

.agents__details__list tbody td {
    padding: 12px;
    border-top: 1px solid #ddd;
}

.agents__details__list thead th:nth-child(1) {
    width: 200px;
}

.agents__details__list thead th:nth-child(2) {
    width: 200px;
}

.agents__details__list thead th:nth-child(3) {
    width: 200px;
}

.agents__details__list thead th:nth-child(4) {
    width: 200px;
}

.agents__details__list thead th:nth-child(5) {
    width: 200px;
}

.agents__details__list thead th:nth-child(6) {
    width: 200px;
}

.agent__table {
    width: min-content;
    border-collapse: collapse;
    text-align: left;
}

.agent__table thead th {
    color: #69070D;
    text-align: left;
    padding: 12px;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
}

.agent__table tbody td {
    padding: 12px;
    border-top: 1px solid #ddd;
    white-space: nowrap;
}

.agent__table thead th:nth-child(2) {
    width: 500px;
}

.agent__table thead th:nth-child(3) {
    width: 100px;
}

.resume__field__email{
    text-decoration: underline;
    cursor: pointer;
}

.email-edit-modal>input{
    padding: 4px 10px;
    font-size: 16px;
}

.email-edit-modal{
    display: flex;
    align-items: center;
    gap: 10px;
}

.email-edit-modal>button{
    border: 2px solid #69070D;
    border-radius: 5px;
    padding: 3px 10px;
    box-sizing: border-box;
    transition: .5s ease;
}

.email-edit-modal>button:hover{
    background-color: #69070D;
    transition: .5s ease;
    color: white;
}
