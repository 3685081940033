.points__first__block>p>span{
    text-decoration: underline;
    cursor: pointer;
}

.point__table{
    width: 70%;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
    border-collapse: collapse;
    margin-bottom: 40px;
}

.point__table>thead>tr{
    height: 60px;
    background-color: #68070D;
    font-size: 20px;
    color: white;
    gap: 0;
}

.point__table>thead>tr>th{
    font-weight: 400;
}

.point__table>tbody>tr{
    height: 50px;
    font-size: 18px;
    border-bottom: 2px solid #ddd;
}

.point__table>tbody>tr:hover{
    background-color: #ebeef3;
}