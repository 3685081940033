.form__container {
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
}

.form__group__global {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.form__group label {
    width: 250px;
    margin-bottom: 8px;
    font-weight: 400;
}

.form__group input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.form__group input:focus {
    border-color: #666;
}

.submit__btn {
    width: 300px;
    padding: 12px;
    margin-top: 20px;
    background-color: #6366f1;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.submit__btn:hover {
    background-color: #4f46e5;
}