.home {
    background-image: url('/public/homeBackground.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    position: relative;
    top: 0;
}

.home__title__block>ul>li{
    color: #000;
}

.home__title__block>ul{
    margin: 0;
}

.home__first__block {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    align-content: center;
}

.home__first__block>.title{
    margin-bottom: 30px;
}

.slogan__text {
    font-size: 24px;
    margin-bottom: 40px;
}

.home__second__block {
    display: flex;
    justify-content: space-between;
    margin-left: 10%;
    color: #68070D;
    overflow-x: hidden;
    overflow: hidden;
    align-items: center;
}

.home__title__block{
    width: 500px; 
}

.home__title__block>p{
    color: #000;
}

.home__laptop{
    width: 60%;
}

.home__laptop>img{
    width: 90%;
    object-fit: cover;
    margin-left: 50px;
}

.home__third__block{
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.home__third__block>.title__block__center{
    width: 250px;
}

.home__third__block>.title{
    margin: 20px 0 40px 0;
}

.home__content{
    display: flex;
    gap: 2%;
}

.home__content__item{
    width: 32%;
    text-align: start;
}

.home__content__item>.subtitle{
    margin-top: 10px;
    color: #68070D;
}

.home__content__item>img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 25px;
}

.home__content__item:nth-child(2)>img{
    height: 400px;
}

.home__content__item:nth-child(1)>img,
.home__content__item:nth-child(3)>img{
    margin-top: 50px;
}

@media(max-width:1225px) {
    .home__title__block{
        width: 40%;
    }
}

@media(max-width:950px) {
    .slogan__text {
        font-size: 20px;
        margin-bottom: 30px;
    }
}

@media(max-width:750px) {
    .home__first__block{
        padding-top: 10px;
    }

    .home__second__block{
        flex-direction: column;
        height: auto;
    }

    .home__title__block{
        width: 100%;
    }

    .home__content{
        flex-direction: column;
        row-gap: 40px;
        margin-top: 80px;
    }

    .home__content__item{
        width: 100%;
        padding-bottom: 20px;
        text-align: center;
    }

    .home__content__item:last-child{
        border: none;
    }

    .home__content__item>img{
        width: 70%;
    }

    .home__content__item:nth-child(2)>img{
        height: 300px;
    }

    .home__content__item:nth-child(1)>img,
    .home__content__item:nth-child(3)>img{
        margin: 0;
    }
}

@media(max-width:425px) {
    .home__content__item>img{
        width: 100%;
        height: 200px;
    }

    .home__content__item:nth-child(2)>img{
        height: 200px;
    }
}

@media(max-width:376px) {
    .home__second__block{
        height: auto;
    }
}