.blog__content {
    display: flex;
    flex-direction: column;
}

.blog__general__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blog__general__title div {
    width: 50%;
}

.blog__general__title p {
    width: 40%;
}

.blog__row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 2%;
    row-gap: 20px;
    margin-bottom: 40px;
}

.block__item {
    position: relative;
    width: 32%;
    height: 350px;
    overflow: hidden;
    border-radius: 20px;
    transition: .5s ease;
    cursor: pointer;
}

.block__item:nth-child(3n+1) {
    clear: both;
}

.block__item:hover {
    transition: .5s ease;
    margin-top: -10px;
    margin-bottom: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

.block__item:hover img {
    transition: .5s ease;
    transform: scale(1.1);
}

.block__item>img {
    height: 100%;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    transition: .5s ease;
}

.blog__head {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    color: #68070D;
    padding: 7px 10px 10px 10px;
    border-radius: 15px;
    background-color: rgba(243, 245, 249, 0.5);
}

.blog__title {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    padding: 10px;
    border-radius: 15px;
    background-color: rgba(243, 245, 249, 0.5);
    margin-right: 10px;
    color: #68070D;
}

.blog__tags__block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0 50px 0;
}

.blog__tag {
    font-size: 20px;
    font-weight: 500;
    border: 2px solid #68070D;
    color: #68070D;
    border-radius: 20px;
    padding: 10px 15px 14px 15px;
    cursor: pointer;
    box-sizing: border-box;
    transition: .5s ease;
    display: flex;
    align-items: center;
}

.blog__tag:hover {
    transition: .5s ease;
    color: white;
    background-color: #68070D;
}

.blog__tag.active {
    background-color: #68070D;
    color: white;
}

.cross {
    margin-left: 20px;
    font-size: 20px;
    vertical-align: middle;
}

.blog__count__block {
    margin-bottom: 50px;
    color: #68070D;
    font-size: 24px;
}

.pagination {
    margin-top: 40px;
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0;
}

.pagination button {
    border: none;
    color: #555;
    font-size: 22px;
    cursor: pointer;
}

.pagination button,
.page-item {
    width: 40px;
    height: 40px;
    text-align: center;
    align-content: center;
    border-radius: 50%;
}

.page-item.active {
    background-color: #ddd;
}

.page-item button {
    background: none;
    border: none;
    color: #555;
    font-size: 22px;
    cursor: pointer;
    outline: none;
}

.page-item.active button {
    font-weight: bold;
    color: #68070D;
}

.page-item button:disabled {
    color: #ddd;
}

.page-item button:hover:not(:disabled) {
    color: #000;
}

@media(max-width:1024px) {
    .block__item {
        width: 49%;
        height: 400px;
    }

    .pagination button,
    .page-item{
        width: 40px;
        height: 40px;
    }
}

@media(max-width:768px) {
    .blog__general__title {
        flex-direction: column;
    }

    .blog__general__title>div,
    .blog__general__title>p {
        width: 100%;
    }

    .blog__tag,
    .blog__tag.active {
        font-size: 20px;
    }

    .blog__tags__block {
        gap: 5px;
    }
}

@media(max-width:500px){
    .blog__row{
        flex-direction: column;
    }

    .block__item{
        width: 100%;
        height: 300px;
    }

    .blog__head>.blog__date,
    .blog__title{
        font-size: 16px;
    }

    .page-item button{
        font-size: 20px;
    }
}