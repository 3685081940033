.head__team {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;
}

.card {
    position: relative;
    width: 24%;
    height: 400px;
    border-radius: 20px;
    overflow: hidden;
    /* cursor: pointer; */
}

.card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s ease;
}

.card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    color: white;
    transition: .5s ease;
}

.card__name {
    font-size: 24px;
    border-radius: 5px;
    align-self: flex-start;
    margin-bottom: 10px;
}

.card__position {
    font-size: 18px;
    align-self: flex-start;
}

.card:hover {
    transition: .5s ease;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

.card:hover .card__image {
    transition: .5s ease;
    transform: scale(1.05);
}

.card:hover .card__overlay {
    transition: .5s ease;
    opacity: 1;
}

.linkedin__icon{
    width: 20px;
    height: 20px;
}

.link{
    width: 20px;
}

@media(max-width:768px){
    .card{
        width: 49%;
        height: 350px;
        margin: 8px 0;
    }
}

@media(max-width:500px){
    .card{
        width: 100%;
        height: 450px;
    }
}