.nav__admin{
    width: 340px;
    height: 90vh;
    background-color: #730901;
    color: white;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
}

.nav__block{
    padding: 20px 0;
}

.nav__category{
    padding: 0 20px;
    width: 100%;
    font-weight: 600;
    margin-bottom: 20px;
}

.nav__item{
    display: flex;
    gap: 10px;
    width: 257.5;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
}

.nav__item img{
    width: 30px;
}

.nav__item:hover{
    background-color: #7b221c;
}

.nav__item.active{
    background-color: #7b221c;
}

.count__agents{
    width: 50%;
    text-align: end;
}