.team__list {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.team__list thead th {
    color: #69070D;
    text-align: left;
    padding: 12px;
    font-weight: 300;
}

.team__list tbody td {
    padding: 12px;
    border-top: 1px solid #ddd;
}

.team__list tbody{
    align-items: center;
}

.team__list tbody td:nth-child(1) {
    padding: 0;
}

.team__list thead th:nth-child(2) {
    width: 200px;
}

.team__list thead th:nth-child(3) {
    width: 250px;
}

.team__list thead th:nth-child(4) {
    width: 200px;
}

.team__list thead th:nth-child(5) {
    width: 200px;
}

.article__actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.team__item>td>img{
    width: 50px;
    height: 50px;
    margin: 5px 0 0 10px;
    object-fit: cover;
}