.header__web {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.header__web>div {
    width: 80%;
    margin: 5px auto;
    display: flex;
    justify-content: space-between;
    height: 80%;
}

.logo {
    cursor: pointer;
    width: 120px;
    height: 30px;
    align-self: center;
}

.nav {
    display: flex;
    gap: 5px;
}

.nav.open {
    max-height: 500px;
    visibility: visible;
    opacity: 1;
    transition: max-height 0.5s ease, visibility 0s, opacity 0.5s ease;
}

.nav__link {
    font-size: 17px;
    cursor: pointer;
    padding: 11.5px 13px;
    border-radius: 15px;
    transition: .5s ease;
    text-align: center;
}

.nav__link:hover {
    transition: .5s ease;
    background-color: #F3F5F9;
}

.btns__block{
    height: 100%;
    align-content: center;
}

.header__btn__login {
    height: 100%;
    box-sizing: border-box;
    font-size: 17px;
    cursor: pointer;
    padding: 0 15px;
    border-radius: 15px;
    border: 2px, solid, #68070D;
    color: #68070D;
    transition: .5s ease;
    text-align: center;
}

.header__btn__login:last-child{
    margin-left: 5px;
}

.header__btn__login:hover {
    color: white;
    transition: .5s ease;
    background-color: #68070D;
}

.nav__link.active,
.header__btn__login.active {
    background-color: #68070D;
    color: #fff;
    transition: .5s ease;
}

.burger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 22px;
    cursor: pointer;
    margin-top: 15px;
}

.burger span {
    width: 100%;
    height: 3px;
    background-color: #68070D;
    transition: 0.5s ease;
}

.burger span:nth-child(2) {
    width: 80%;
}

.burger.open .first__span {
    transform: rotate(45deg);
    position: relative;
    top: 9.5px;
}

.burger.open .second__span {
    opacity: 0;
}

.burger.open .third__span {
    transform: rotate(-45deg);
    position: relative;
    top: -9.5px;
}

@media (max-width: 1100px) {

    .nav {
        display: flex;
        gap: 5px;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: max-height 0.5s ease, visibility 0s 0.5s, opacity 0.5s ease;
    }

    .nav {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: 60px;
        width: 200px;
        padding: 0 20px 20px 20px;
        background-color: #fff;
        border-radius: 0 0 0 20px;
    }

    .btns__block{
        display: flex;
        gap: 5px;
        flex-direction: column;
    }

    .header__btn__login:nth-child(2){
        margin: 0;
    }

    .burger {
        display: flex;
    }

    .header__btn__login {
        transition: none;
        padding: 11.5px 15px;
    }
}
