html,
body {
    width: 100%;
    height: 100%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    cursor: default;
    scroll-behavior: smooth;
    scrollbar-width: none;
}

img {
    -webkit-user-drag: none;
}

button {
    all: unset;
    cursor: pointer;
}

.main {
    width: 100%;
    padding-top: 60px;
}

.white__background {
    width: 100%;
    padding: 80px 0;
    background-color: #fff;
}

.gray__background {
    padding: 80px 0;
    background-color: #f1f9fb;
}

.darkgray__background {
    width: 100%;
    padding: 80px 0;
    background-color: #eeeded;
}

.title__block {
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 15px;
}

.title__block__center {
    width: 165px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 15px;
}

.title__line {
    width: 50px;
    height: 2px;
    background-color: #68070D;
}

.title__text {
    font-size: 20px;
    color: #68070D;
    font-weight: 500;
}

.title {
    font-weight: 600;
    font-size: 34px;
    line-height: 1.2;
}

.item__line__1440,
.item__line__1024 {
    width: 2px;
    height: 100px;
    background-color: #ccc;
}

.item__line__1024 {
    display: none;
}

.page__title {
    font-size: 50px;
    font-weight: 600;
}

.description {
    font-size: 24px;
    font-weight: 350;
    margin: 15px 0;
}

.subtitle {
    font-weight: 600;
    font-size: 24px;
}

p,
li {
    font-size: 18px;
}

a {
    color: black;
}

.container {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
}













.title__admin {
    font-size: 26px;
    font-weight: 500;
    color: #888;
    margin-bottom: 30px;
}

.subtitle__admin {
    font-size: 18px;
    color: #888;
}

.subtitle__optional__admin {
    font-size: 18px;
    color: #888;
}

.subtitle__admin::after {
    content: " *";
    color: red;
}

.main__admin {
    display: flex;
    padding-top: 70px;
    overflow-y: hidden;
    height: calc(100vh - 70px);
}

.main__content__admin {
    width: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.all__content__admin {
    padding: 20px 30px;
}

.content__block__admin {
    padding: 20px;
    background-color: #F3F5F9;
    box-shadow: 0px 4px 10px rgb(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-bottom: 40px;
}

.list__title__admin {
    font-size: 22px;
    color: #730901;
    font-weight: 300;
    margin-bottom: 30px;
}













@media(max-width:1024px) {
    .item__line__1440 {
        display: none;
    }

    .item__line__1024 {
        display: flex;
    }
}

@media(max-width:950px) {
    .title {
        font-size: 30px;
    }

    .page__title {
        font-size: 36px;
    }

    .description {
        font-size: 22px;
    }

    p,
    li,
    a {
        font-size: 16px;
    }

    html,
    body {
        width: 100%;
    }
}

@media(max-width:500px) {
    .item__line__1024 {
        display: none;
    }
}

@media(max-width:426px) {

    .title {
        font-size: 28px;
    }

    .white__background {
        padding: 40px 0;
    }

    .gray__background {
        padding: 40px 0;
    }

    .darkgray__background {
        padding: 40px 0;
    }
}

img {
    overflow: hidden;
}