.header__admin{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: #F3F5F9;
}

.logo__admin{
    width: 345px;
    height: 100%;
    align-content: center;
    background-color: #69070D;
}

.logo__admin img{
    width: 100px;
    margin-left: 20px;
    object-fit: cover;
    cursor: pointer;
}

.header__btns__admin{
    width: 100%;
    height: 100%;
    text-align: end;
    align-content: center;
    padding-right: 100px;
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.header__btns__admin img{
    width: 30px;
    margin-right: 20px;
}