.career__first__block{
    display: flex;
    justify-content: space-between;
}

.career__content{
    width: 40%;
}

.career__content>.title{
    margin: 20px 0 30px 0;
}

.career__first__block>.images {
    width: 50%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
}

.career__first__block>.images>img {
    width: 47.5%;
    height: 220px;
    margin-bottom: 20px;
    border-radius: 20px;
    object-fit: cover;
}

.career__second__block{
    width: 100%;
    text-align: center;
}

.career__second__block p{
    margin-bottom: 50px;
}

.vacancy__block{
    width: 100%;
    background-color: #f3f5f9;
    text-align: start;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px 30px;
    border-radius: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    row-gap: 20px;
}

.vacancy__title{
    font-size: 24px;
    font-weight: 600;
}

.vacancy__details{
    padding: 7px 10px;
    color: #888;
    font-weight: 300;
}

.vacancy__btn{
    /* padding: 7px 30px; */
    width: 130px;
    text-align: center;
    height: 50px;
    border: 2px solid #68070D;
    border-radius: 20px;
    color: #68070D;
    font-size: 18px;
    transition: .5s ease;
    /* box-sizing: border-box; */
}

.vacancy__btn:hover{
    background-color: #68070D;
    color: white;
    transition: .5s ease;
}


@media(max-width:1024px) {
    .career__first__block>.images>img {
        width: 100%;
        height: 250px;
    }
}

@media(max-width:950px) {
    .career__first__block>.images>img {
        height: 200px;
    }
}

@media(max-width:768px) {
    .career__first__block {
        flex-direction: column;
    }

    .career__first__block>.career__content {
        width: 100%;
        margin-bottom: 20px;
    }

    .career__first__block>.images {
        width: 100%;
    }

    .career__first__block>.images>img {
        width: 47.5%;
    }
}

@media(max-width:500px) {
    .career__first__block>.images>img {
        width: 100%;
    }

    .vacancy__title{
        font-size: 22px;
    }

    .vacancy__details{
        padding: 0;
    }
}

@media(max-width:375px){
    .vacancy__btn{
        padding: 10px;
    }

    .vacancy__details{
        font-size: 14px;
    }

    .vacancy__block{
        padding: 15px;
    }
}