.file-upload {
    margin-bottom: 20px;
}

.file-upload label {
    font-size: 14px;
    font-weight: bold;
}

.file-drop-zone {
    border: 1px solid #d3d3d3;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    text-align: center;
    transition: background-color 0.3s ease;
}

.file-drop-zone.drag-active {
    background-color: #e0f7fa;
}

.file-upload-label {
    color: #0056b3;
    text-decoration: underline;
    cursor: pointer;
}

.file-preview {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.file-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.file-icon {
    width: 40px;
    margin-right: 15px;
}

.file-details {
    flex-grow: 1;
}

.file-name {
    font-weight: bold;
}

.file-size {
    font-size: 12px;
    color: #6c757d;
}

.delete-btn {
    color: red;
    border: none;
    background: none;
    cursor: pointer;
}