.about__first__block {
    display: flex;
    justify-content: space-between;
}

.about__first__block>.about__content {
    width: 40%;
}

.about__first__block>.about__content>.title{
    margin: 30px 0;
}

.about__first__block>.images {
    width: 50%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
}

.about__first__block>.images>img {
    width: 47.5%;
    height: 220px;
    margin-bottom: 20px;
    border-radius: 20px;
    object-fit: cover;
}

.about__second__block {
    display: flex;
    justify-content: space-between;
    gap: 5%;
}

.vision,
.mission {
    width: 47.5%;
    background-color: #F3F5F9;
    padding: 20px 30px;
    border-radius: 20px;
}

.vision>.title,
.mission>.title{
    margin-top: 0;
}

.about__third__block>.title{
    margin: 20px 0;
}

.about__fourth__block>.title{
    margin: 20px 0;
}

.img__background {
    width: 70px;
    height: 70px;
    border-radius: 20px;
    align-content: center;
    background-color: #68070D;
    text-align: center;
    box-sizing: border-box;
}

.img__background img {
    width: 55px;
    border-radius: 16px;
}

.reasons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    row-gap: 30px;
    gap: 30px;
    margin-top: 40px;
}

.item__text {
    width: 70%;
    color: #68070D;
    font-weight: 500;
    font-size: 18px;
}

.reasons__item {
    width: 25%;
    display: flex;
    align-items: center;
    gap: 15px;
}

.about__fourth__block>.subtitle {
    margin-top: 40px;
}

.about__btn__register {
    width: 200px;
    font-size: 22px;
    cursor: pointer;
    padding: 11px 10px 15px 10px;
    border-radius: 15px;
    border: 2px, solid, #68070D;
    background-color: #68070D;
    color: white;
    margin: 0 auto;
    transition: .5s ease;
    text-align: center;
}

.about__btn__register:hover {
    transition: .5s ease;
    background-color: #fff;
    border: 2px solid #68070D;
    color: #68070D;
    box-shadow: 0 4px 6px gray;
}

@media(max-width:1024px) {
    .about__first__block>.images>img {
        width: 100%;
        height: 250px;
    }
    .reasons__item {
        width: 40%;
    }
}

@media(max-width:950px) {
    .about__first__block>.images>img {
        height: 200px;
    }


    .img__background {
        width: 60px;
        height: 60px;
    }
    
    .img__background img {
        width: 45px;
        border-radius: 16px;
    }
}

@media(max-width:768px) {
    .about__first__block {
        flex-direction: column;
    }

    .about__first__block>.about__content {
        width: 100%;
        margin-bottom: 20px;
    }

    .about__first__block>.images {
        width: 100%;
    }

    .about__first__block>.images>img {
        width: 47.5%;
    }

    .item__text {
        width: 70%;
    }
}

@media(max-width:500px) {
    .about__first__block>.images>img {
        width: 100%;
    }

    .about__second__block {
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0;
    }

    .vision,
    .mission {
        width: 100%;
        margin: 7px 0;
        box-sizing: border-box;
    }

    .reasons{
        flex-direction: column;
        gap: 15px;
    }

    .reasons__item{
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 2px solid #b5b5b6;
    }

    .reasons__item:last-child{
        border-bottom: none;
        padding-bottom: 0;
    }

    .item__text{
        width: 80%;
    }
}