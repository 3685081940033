.margin__bottom{
    padding-bottom: 80px;
}

.edit__form{
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    align-items: center;
}

.edit__form input,
.edit__form select{
    border: 1px solid #ccc;
    width: 1000px;
    height: 40px;
    font-size: 16px;
    border-radius: 5px;
}

.edit__form input{
    width: 993px;
}   

.save__btn {
    background-color: #22c55e;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 150px;
    text-align: center;
    margin-left: 200px;
}

.save__btn:hover {
    background-color: #16a34a;
}