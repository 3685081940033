.content-container{
    width: 60%;
    margin: 0 auto;
}

.content-container img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}